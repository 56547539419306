// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-free-consultation-tsx": () => import("./../../../src/pages/free-consultation.tsx" /* webpackChunkName: "component---src-pages-free-consultation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-of-use-tsx": () => import("./../../../src/pages/legal/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-phases-index-tsx": () => import("./../../../src/pages/phases/index.tsx" /* webpackChunkName: "component---src-pages-phases-index-tsx" */),
  "component---src-pages-services-ecommerce-index-tsx": () => import("./../../../src/pages/services/ecommerce/index.tsx" /* webpackChunkName: "component---src-pages-services-ecommerce-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-jamstack-website-index-tsx": () => import("./../../../src/pages/services/jamstack-website/index.tsx" /* webpackChunkName: "component---src-pages-services-jamstack-website-index-tsx" */),
  "component---src-pages-services-progressive-web-app-index-tsx": () => import("./../../../src/pages/services/progressive-web-app/index.tsx" /* webpackChunkName: "component---src-pages-services-progressive-web-app-index-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

